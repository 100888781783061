@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.product {
  margin: 24px 0;

  @include m.bp(null, sm) {
    gap: 16px;

    > * {
      grid-column: 1 / -1;
    }
  }

  @include m.bp(sm) {
    margin: 32px;
  }

  @include m.bp(lg) {
    margin: 40px 0;
  }

  &__basic-info {
    grid-row: 1;

    @include m.bp(sm) {
      grid-column: span 7 / -1;
    }

    @include m.bp(md) {
      grid-column: span 6 / -1;
    }

    @include m.bp(xl) {
      grid-column: span 10 / -1;
    }

    .heading__title {
      padding-top: 0;
    }
  }

  &__images {
    grid-column: 1 / -1;
    grid-row: 2;
    width: 100%;

    @include m.bp(sm) {
      grid-column: 1 / span 5;
      grid-row: 1;
    }

    @include m.bp(md) {
      grid-row: 1 / span 2;
    }

    @include m.bp(lg) {
      grid-column: 1 / span 5;
    }

    @include m.bp(xl) {
      grid-column: 1 / span 7;
    }

    .picture, .video__media {
      border-radius: unset;
    }

    &__main-image {
      border: 1px solid var(--input-field-border);
      background: var(--mono-0);
    }

    &__image-picker {
      @include m.grid(4, 5, 5, 8, 1fr);
      margin-top: 4px;
      grid-gap: 8px;

      @include m.inputMethod(touch) {
        @include m.bp(null, sm) {
          @include m.flex(start, center);
          @include m.hide-scrollbars;
          margin: 4px (map-get(v.$section-padding-sizes, xs) * -1);
          padding: 0 map-get(v.$section-padding-sizes, xs);
          width: calc(100% + (2 * map-get(v.$section-padding-sizes, xs)));
          overflow-x: scroll;

          &__button {
            flex: 0 0 20%;
          }
        }
      }

      &__button {
        padding: 0;
        background: white; //TODO: Create variable for default image background
        border: 1px solid var(--input-field-border);
        cursor: pointer;
        transition: v.$button-transition;

        &[data-image-selected] {
          border-color: var(--brand-main);

          .image {
            opacity: 1;
          }
        }

        &__image {

          .image {
            opacity: 0.64;
            transition: v.$transparent-image-transition;
          }
        }

        @include m.interaction() {
          border-color: var(--brand-main-active);

          .image {
            opacity: 1;
          }
        }
      }
    }
  }

  &__discounts {
    grid-column: 1 / -1;
    background: red;

    @include m.bp(md) {
      grid-row: 2 / span 1;
      grid-column: span 6 / -1;
    }
  }

  &__offers {
    grid-column: 1 / -1;

    @include m.bp(md) {
      grid-column: span 6 / -1;
    }

    @include m.bp(xl) {
      grid-column: span 10 / -1;
    }

    .input-group__container {

      input.radio {
        display: none;
      }

      .input-group {
        @include m.flex(start, center);
        margin: 0;
        gap: 8px;

        @include m.bp(sm) {
          gap: 16px;
        }

        @include m.bp(md) {
          gap: 24px;
        }

        &__input:checked + .input-group__label {
          border-color: var(--brand-main);
          background: rgba(var(--brand-main-rgb), 0.08);

          .image-fallback .icon {
            color: var(--brand-main);
          }
        }

        &__label {
          display: grid;
          grid-template-columns: 64px auto;
          gap: 8px;
          padding: 0 8px 0 0;
          border: 1px solid var(--input-field-border);
          border-radius: var(--input-border-radius);
          background: var(--mono-0);
          overflow: hidden;
          transition: v.$input-transition;

          @include m.bp(sm) {
            grid-template-columns: 80px auto 1fr;
            padding: 0 12px 0 0;
            gap: 16px;
          }

          @include m.bp(md) {
            grid-template-columns: 80px auto;
          }

          @include m.bp(lg) {
            grid-template-columns: 96px auto 1fr;
          }

          @include m.interaction() {
            border-color: var(--brand-main);
            background: rgba(var(--brand-main-rgb), 0.16) !important;

            .image-fallback .icon {
              color: var(--brand-main);
            }
          }

          @include m.interaction(active) {
            background: rgba(var(--brand-main-rgb), 0.32) !important;

            .image-fallback .icon {
              opacity: 0.64;
            }
          }

          &__image {
            grid-row: 1 / span 2;
            min-height: 64px;
            align-self: stretch;
            background: var(--mono-0);
            border-radius: unset;

            @include m.bp(sm) {
              grid-row: 1;
              min-height: 80px;
            }

            @include m.bp(md) {
              grid-row: 1 / span 2;
            }

            @include m.bp(lg) {
              flex: 0 0 96px;
              min-height: 96px;
            }

            .image-fallback {
              padding: 8px;
              box-sizing: border-box;

              &__icon {
                font-size: 2em;
              }
            }

            &--hidden {
              visibility: hidden;
            }
          }

          &__copy {
            @include m.flex(start, start, column);
            margin: 8px 0 0;

            &:last-child {
              margin-bottom: 8px;
            }

            &__name {
              display: inline-block;
              font-weight: v.$font-semi-bold;
              overflow-wrap: anywhere;
            }

            &__description {
              display: inline-block;
              margin: 4px 0 0;
              overflow-wrap: anywhere;
            }
          }

          &__pricing {
            @include m.flex(end, center);
            gap: 8px;
            grid-column: 2 / -1;
            margin-bottom: 8px;

            &__price {
              font-weight: v.$font-semi-bold;

              @include m.bp(sm) {
                margin: 8px 0;
              }

              &--discounted {
                font-weight: v.$font-regular;
                text-decoration: line-through;
              }
            }

            &__discount-price {
              margin: 0;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .banner {
      margin-top: 40px;
    }

    &__add-to-cart {
      @include m.flex(start, stretch, column);
      gap: 8px;
      margin: 40px 0 24px auto;
      padding: 0;

      &:last-child {
        margin-bottom: unset;
      }

      @include m.bp(sm) {
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
      }

      .input-group {
        flex: 1 1 auto;
        margin: 0;
        gap: 0;

        @include m.bp(sm) {
          max-width: 160px;
        }

        &__field {
          flex: 1;

          input {
            width: 100%;
            margin: 0;
            border-left: unset;
            border-right: unset;
            border-color: var(--button-main);
            transition: v.$button-transition;

            @include m.interaction() {
              border-color: var(--button-main-active);
            }
          }
        }

        .button {

          @include m.bp(null, sm) {
            flex: 0 0 96px;
          }

        }
      }

      > .button {
        flex: 1 0 auto;

        @include m.bp(sm) {
          flex: 0 0 auto;
        }
      }
    }
  }

  &__details {
    grid-column: 1 / -1;
    grid-row: 4;

    @include m.bp(sm) {
      grid-row: 3;
    }

    @include m.bp(lg) {
      grid-column: 1 / span 8;
    }

    @include m.bp(xl) {
      grid-column: 1 / span 10;
    }
  }

  &__downloads {
    grid-row: 5;

    @include m.bp(sm) {
      grid-row: 4;
    }

    @include m.bp(md) {
      grid-column: 1 / span 5;
      grid-row: 3;
    }

    @include m.bp(lg) {
      grid-column: 1 / span 5;
    }

    &__button {
      margin: 8px 0 0;
    }
  }
}

.related-products {
  @include m.grid(6, 12, 12, 16);
  grid-column: 1 / -1;
  grid-gap: var(--grid-gap-xs);

  @include m.bp(sm) {
    grid-gap: var(--grid-gap-sm);
  }

  .heading__title {
    padding-top: unset;
  }

  .product-card {
    @include m.bp(sm) {
      grid-column: span 6;
    }

    @include m.bp(md) {
      grid-column: span 4;
    }

    @include m.bp(lg) {
      grid-column: span 3;
    }

    @include m.bp(xl) {
      grid-column: span 4;
    }
  }
}