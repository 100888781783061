@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.product-card {
  @include m.flex(between, start, column);
  grid-column: span 3;
  margin: 0;
  background: var(--cards-background);
  border: 1px solid var(--card-border);
  border-radius: var(--shape-border-radius);
  text-decoration: none;
  overflow: hidden;

  @include m.bp(sm) {
    grid-column: span 4;
  }

  @include m.bp(lg) {
    grid-column: span 4;
  }

  @include m.bp(xl) {
    grid-column: span 5;
  }

  .picture, .video__media {
    background: white; // TODO: Create theme setting
    border-radius: unset;
    border-bottom: 1px solid var(--card-border);

    .image {
      padding: 16px; // TODO: Create 'image padding' toggle in webshop settings
      box-sizing: border-box;
    }
  }

  &__copy {
    @include m.flex(between, start, column);
    flex: 1 1 auto;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;

    &__title {
      font-size: 0.88rem;
      padding: 0 0 0.32rem;
      font-weight: v.$font-regular;
      hyphens: auto;
    }

    &__pricing {

      &__price {
        font-weight: v.$font-bold;
        color: var(--text-color-dark);

        &--discount {
          text-decoration: line-through;
          margin-right: 8px;
        }
      }

      &__discount-price {
        padding: .16rem .32rem;
        background: var(--brand-accent);
        color: var(--brand-accent-label);
        white-space: nowrap;
      }
    }
  }
}