@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.checkout-header {
  background: unset;
  padding-bottom: 16px;
  max-width: v.$section-page-content-width;

  @include m.bp(sm) {
    padding-top: 64px;
  }

  .heading {
    margin-top: 24px;

    &__title {
      padding-top: unset;
    }
  }
}

.progress-bar {
  @include m.flex(start, center);
  grid-column: 1 / -1;
  gap: 16px;
  margin-top: 24px;

  &__stage {
    @include m.flex(start, center);

    &--active &__button__label {
      display: initial;
    }

    &__button {
      @include m.flex(start, center);
      gap: 8px;
      text-decoration: none;

      @include m.bp(sm) {
        gap: 12px;
      }

      &__icon {
        flex: 0 0 32px;
      }

      &__label {
        display: none;
        color: var(--text-color-dark);

        @include m.bp(sm) {
          display: initial;
        }
      }
    }

    &__spacer {
      display: block;
      height: 1px;
      width: 16px;
      background: var(--mono-4);
    }

    &:last-child &__spacer {
      display: none;
    }
  }
}

.checkout-form {
  max-width: v.$section-page-content-width;

  @include m.bp(sm) {
    margin: 0;
  }

  &__action {
    @include m.flex(end, center);
    grid-column: 1 / -1;
    flex: 1 1 auto;
    margin: 16px 0 64px;
    width: 100%;
    padding: 0;

    @include m.bp(sm) {
      margin: 24px 0 48px;
    }
  }

  &__payment {
    @include m.flex(between, center, column);
    gap: 32px;
    margin: 16px 0 48px;
    grid-column: 1 / -1;

    @include m.bp(sm) {
      flex-direction: row;
      margin: 24px 0 48px;
      gap: 12px;
    }

    &__note {
      @include m.flex(start, center);
      flex: 1 1 100%;
      gap: 12px;

      &__icon {
        margin-top: 6px;
        color: var(--brand-main);
        font-size: 1.6rem;
      }

      &__text {
        margin: 0;
        color: var(--mono-6);
        font-size: 0.8rem;
      }
    }

    .button {
      width: 100%;

      @include m.bp(sm) {
        width: auto;
      }
    }
  }
}

.selected-method {
  @include m.flex(start, center);
  gap: 8px;
  padding: 16px 12px;

  &__image {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;

    @include m.bp(sm) {
      flex: 0 0 64px;
      width: 64px;
      height: 64px;
    }
  }

  &__label {
    margin: 0 0 0 8px;
  }

  &__image + &__label {
    margin: 0;
  }
}

/* Custom radio input styling */
.checkout-method-picker {
  margin: 12px 0 0;

  .input-group__container {
    @include m.flex(start, center, row);
    flex-wrap: wrap;
    gap: 12px;
    margin: 0;

    @include m.bp(sm) {
      gap: 16px;
      flex-direction: row;
    }
  }
}

.input-group--checkout-method {
  margin: 0;
  flex: 0 0 auto;

  .radio {
    display: none;

    &:checked + label {
      border-color: var(--brand-main);
      background: rgba(var(--brand-main-rgb), 0.08);
    }
  }

  .label {
    @include m.flex(between, center, column);
    flex-wrap: wrap;
    padding: 4px 8px 8px;
    border: 1px solid var(--input-field-border);
    border-radius: var(--input-border-radius);
    background: var(--input-field-background);
    min-width: 116px;
    height: 100%;
    transition: 0.24s cubic-bezier(0.22, 1, 0.36, 1);

    @include m.bp(sm) {
      gap: 12px;
      padding: 8px 8px 12px;
    }

    @include m.interaction() {
      border-color: var(--brand-main-active);
      background: rgba(var(--brand-main-rgb), 0.16);
    }

    &--disabled {
      border-color: var(--mono-5);
      background: var(--mono-2);
      cursor: default;

      @include m.interaction() {
        border-color: var(--mono-5);
        background: var(--mono-2);
      }
    }

    &__image {
      width: 48px;
      height: 48px;

      @include m.bp(sm) {
        width: 64px;
        height: 64px;
      }
    }
  }
}

.pricing-overview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 18px 12px;
  margin: 0;
  list-style-type: none;

  @include m.bp(sm) {
    padding: 20px;
  }

  &__label {
    font-weight: v.$font-semi-bold;
  }

  &__price {
    justify-self: end;

    &--lg * {
      color: var(--brand-main);
    }
  }

  &__empty-row {
    grid-column: 1 / -1;
    height: 12px;
  }
}

// Login banner
.checkout-login-banner {
  grid-column: 1 / -1;

  &__container {
    border: 1px solid var(--state-info);
    background: rgba(var(--state-info-rgb), 0.012);
    margin-top: 16px;
    padding: 12px;
    border-radius: var(--shape-border-radius);

    #login-form {
      grid-auto-flow: dense;
      margin: 0;
      padding: 0;
    }

    .heading {
      margin-top: 0;

      &__title {
        font-size: 1.12em;
        color: var(--state-info-label);
        padding-top: 0;
      }

      &__description {
        margin-top: 0;
      }
    }

    .link {
      grid-column: 1 / -1;
      font-size: 0.88em;

      &__label {
        font-size: inherit;
      }
    }

    .button {
      grid-column: 1 / -1;
      margin: 16px 0 0 auto;

      @include m.bp(sm) {
        grid-column: span 4 / -1;
        align-self: end;
      }
    }
  }
}