@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.category-card {
  @include m.flex(between, start, column);
  grid-column: 1 / -1;
  margin: 0;
  background: var(--cards-background);
  border: 1px solid var(--card-border);
  border-radius: var(--shape-border-radius);
  text-decoration: none;
  overflow: hidden;

  @include m.bp(sm) {
    grid-column: span 6;
  }

  @include m.bp(md) {
    grid-column: span 4;
  }

  @include m.bp(lg) {
    grid-column: span 4;
  }

  @include m.bp(xl) {
    grid-column: span 5;
  }

  &__image {
    padding-top: 60%; // TODO: Create 'image height' webshop setting
    background: var(--mono-0); // TODO: Create theme setting
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    border-bottom: 1px solid var(--card-border);

    .image[data-image-fit="contain"] {
      padding: 16px; // TODO: Create 'image padding' toggle in webshop settings
      box-sizing: border-box;
    }
  }

  &__copy {
    @include m.flex(between, start, column);
    flex: 1 0;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;

    &__title {
      font-size: 1rem;
      padding: 0 0 .4rem;
      font-weight: v.$font-regular;
      word-break: break-word;
    }
  }
}