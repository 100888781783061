@use '../../../../../resources/scss/mixins' as m;

.overview {
  .heading {
    margin-bottom: 24px;

    &__title {
      padding-top: unset;
    }

    @include m.bp(sm) {
      margin-bottom: 40px;
    }
  }

  &__paginator {
    grid-column: 1 / -1;

    &--product {
      @include m.bp(md) {
        grid-column: span 8 / -1;
      }

      @include m.bp(xl) {
        grid-column: span 14 / -1;
      }
    }
  }

  &__message {
    @include m.flex(center, center, column);
    grid-column: 1 / -1;

    &__icon {
      font-size: 4rem;
      margin: 0 0 2rem;
    }
  }
}

.sub-categories {
  @include m.flex(start, center);
  flex-wrap: wrap;
  grid-column: 1 / -1;
  grid-gap: 8px;
  margin-bottom: 24px;

  @include m.bp(md) {
    grid-column: span 8 / -1;
    grid-gap: 16px;
  }

  @include m.bp(xl) {
    grid-column: span 14 / -1;
  }

  &__title {
    width: 100%;

    @include m.bp(md) {
      padding-top: 0;
    }
  }

  &__button {
    border-color: var(--card-border);
    border-width: 1px; // TODO: This overwrites the default border width. Check if this should be unified
    gap: 8px;

    @include m.bp(sm) {
      padding: 0 12px 0 8px; // TODO: This overwrites the default border width. Check if this should be unified
    }

    &__image {
      width: 48px;
      height: 48px;
    }
  }
}

.products {
  @include m.grid(6, 12, 12, 20);
  grid-column: 1 / -1;
  grid-gap: 12px;

  @include m.bp(sm) {
    grid-gap: 16px;
  }

  @include m.bp(md) {
    grid-column: span 8 / -1;
  }

  @include m.bp(lg) {
    grid-column: span 14 / -1;
  }

  &__product-amount {
    @include m.bp(md) {
      margin: 0;
    }
  }

  &__title {
    padding-top: 0;
  }
}

.categories {
  @include m.grid(6, 12, 12, 12, 20);
  grid-column: 1 / -1;
  grid-gap: 12px;

  @include m.bp(sm) {
    grid-gap: var(--grid-gap-sm);
  }
}