/*
 * Variables
 */
/* Grid sizing */
/* Page and section sizing */
/* Font weights */
/* z-indexes */
/* Select2 dropdown */
/* Button sizing */
/* Image alignments and ratios */
/* Section padding */
/*
 *  Overlays
 */
/* Logo sizes for navigation and footer */
/* Theme colors */
/* Grid columns and gaps */
:root {
  --grid-gap-xs: 16px;
  --grid-gap-sm: 24px;
  --grid-col-xs: 6;
  --grid-col-sm: 12;
  --grid-col-xl: 18;
  --max-grid-width: calc(var(--grid-width) * var(--grid-col-xl) + var(--grid-gap-sm) * var(--grid-col-xl));
}

/*
 * Transitions
 */
/* Element shadows */
/*
 *  # Experimental SCSS Styling Mixins V1.0
 *  A collection of non standard mixins.
 *
 *  Warning!  Some of these mixins use non-standard properties to solve specific cases,
 *            while other mixins impact user accessibility. These mixins should be used carefully
 *            or not at all when more stable options are available.
 */
/*
 * # Hide Scrollbars
 * Hide scrollbars inside the current element.
 *
 * Warning! This mixin impacts accessibility by hiding scrollbars inside an scrolling element.
 *          Make sure to use a replacement element to indicate the scroll position.
 */
/*
 * # Browser Exceptions
 * Create styling for specific browsers.
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *          Only use this if nothing else can solve your case.
 *
 * $browser - String - set the browser name write styling for. | Options: firefox, chrome, safari, internet-explorer
 */
/*
 * # Detects the users inputMethod
 * Only activate your styling when a certain inputMethod is used
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *
 */
/*
 * # Set styling when interacting with an element
 *
 * $mode (optional)
 *        - active -  uses the :active selector instead of :focus and :hover.
 *        - focus  -  only sets :focus and :focus-within.
 *
 * Warning! This feature is still experimental and thus subject to possible changes in the future.
 *
 */
/*
 *  # SCSS Layout Mixins V1.0
 *  A set of mixins to rapidly build layouts.
 *
 */
/*
 * # Breakpoints
 * Add styling for specific screen sizes.
 *
 * $minSize - Set the min-width property | options: xs, sm, md, lg, xl, xxl
 * $maxSize - Set the max-width property | options: xs, sm, md, lg, xl, xxl
 *
 */
/*
 * # Flex layout
 * Create flex layouts inside a container element.
 *
 *
 */
/*
 * # Grid containers
 * Create grid views inside a container element.
 *
 * @param Integer $col-xs - Set the amount of columns for xs and default screen sizes
 * @param Integer $col-sm - Set the amount of columns for sm screen sizes
 * @param Integer $col-lg - Set the amount of columns for lg screen sizes
 * @param Integer $col-xl - Set the amount of columns for xl and xxl screen sizes
 * @param {px, %} $max-col-width - Overwrite the default max width on large screens
 */
/*
 * # Element positioning
 * Shortcuts for absolute, fixed and sticky positioning.
 *
 * $position  - String - Either 'fixed', 'absolute' or 'sticky' to set the css position property.
 * $top       - String - Set the value for the top property.    | Default: null
 * $right     - String - Set the value for the right property.  | Default: null
 * $bottom    - String - Set the value for the bottom property. | Default: null
 * $left      - String - Set the value for the left property.   | Default: null
 */
/*
 * # Section Padding Sizes
 * Renders margin or padding sizes for a container.
 *
 * $mode - either margin or padding.
 */
/*
 *  # SCSS Styling Mixins V1.0
 *  These mixins are made to quickly build SCSS layouts.
 *
 */
/*
 * # Background overlay
 * Adds a dark overlay as :after pseudo selector to backgrounds
 */
/*
 * # Theme color loop
 * Loops over all theme colors to generate theme color variants
 *
 * Available variables in @content:
 * - $type | The theme color type, either 'brand' or 'state'.
 * - $color | The current theme color name.
 */
/*
 * # Card styling
 * All card styling properties in a single mixin.
 */
.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* IE, Legacy Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Chrome, Safari and Opera */
  grid-column: 1/-1;
  width: 100%;
  margin: 24px 0 0;
  padding: 0 16px;
  gap: 4px;
  overflow-x: scroll;
  box-sizing: border-box;
}
.breadcrumbs::-webkit-scrollbar {
  display: none;
}
.breadcrumbs__separator {
  flex: 0;
  color: var(--mono-4);
  font-size: 1.4rem;
}
.breadcrumbs .tag {
  flex: 0 0 auto;
  color: var(--mono-6);
}
.breadcrumbs .tag:focus, .breadcrumbs .tag:focus-within {
  color: var(--brand-main-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .breadcrumbs .tag:hover {
    color: var(--brand-main-active);
  }
}

@media (max-width: 767px) {
  body[data-action-nav] .breadcrumbs {
    margin: 80px 0 0;
  }
}

.cart {
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  flex-direction: column;
  gap: 16px;
  margin: 16px 8px;
  width: calc(100% - 16px);
}
@media (min-width: 768px) {
  .cart {
    gap: 16px;
    width: calc(100% - 32px);
    margin: 16px;
  }
}
.cart__products {
  border: 1px solid var(--card-border);
  overflow: hidden;
  border-radius: var(--shape-border-radius);
}
.cart__note {
  margin: 0;
  text-align: center;
  color: var(--mono-6);
  font-size: 0.88em;
}

.cart-product-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  padding: 8px;
  background: var(--mono-1);
}
@media (min-width: 768px) {
  .cart-product-card {
    gap: 16px;
    flex-wrap: nowrap;
  }
}
.cart-product-card:nth-child(odd) {
  background: var(--mono-2);
}
.cart-product-card p {
  margin: 0;
}
.cart-product-card__image {
  width: 48px;
  height: 48px;
  flex: 0 0 48px;
  background: var(--mono-0);
  border: 1px solid var(--card-border);
}
@media (min-width: 768px) {
  .cart-product-card__image {
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
  }
}
.cart-product-card__image .image-fallback .icon {
  font-size: 2rem;
}
.cart-product-card__copy {
  flex: 1 1 calc(100% - 64px);
  align-self: start;
}
.cart-product-card__copy__title {
  font-weight: 600;
}
.cart-product-card__copy__description {
  font-size: 0.88rem;
  color: var(--mono-6);
}
.cart-product-card__pricing {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 1 100%;
  align-self: center;
}
@media (min-width: 768px) {
  .cart-product-card__pricing {
    flex: 0 0 auto;
  }
}
.cart-product-card__pricing__calculation {
  color: var(--mono-6);
}
.cart-product-card__pricing__result {
  font-weight: 600;
}

.product-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  grid-column: span 3;
  margin: 0;
  background: var(--cards-background);
  border: 1px solid var(--card-border);
  border-radius: var(--shape-border-radius);
  text-decoration: none;
  overflow: hidden;
}
@media (min-width: 768px) {
  .product-card {
    grid-column: span 4;
  }
}
@media (min-width: 1280px) {
  .product-card {
    grid-column: span 4;
  }
}
@media (min-width: 1600px) {
  .product-card {
    grid-column: span 5;
  }
}
.product-card .picture, .product-card .video__media {
  background: white;
  border-radius: unset;
  border-bottom: 1px solid var(--card-border);
}
.product-card .picture .image, .product-card .video__media .image {
  padding: 16px;
  box-sizing: border-box;
}
.product-card__copy {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}
.product-card__copy__title {
  font-size: 0.88rem;
  padding: 0 0 0.32rem;
  font-weight: 400;
  hyphens: auto;
}
.product-card__copy__pricing__price {
  font-weight: 700;
  color: var(--text-color-dark);
}
.product-card__copy__pricing__price--discount {
  text-decoration: line-through;
  margin-right: 8px;
}
.product-card__copy__pricing__discount-price {
  padding: 0.16rem 0.32rem;
  background: var(--brand-accent);
  color: var(--brand-accent-label);
  white-space: nowrap;
}

.category-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  grid-column: 1/-1;
  margin: 0;
  background: var(--cards-background);
  border: 1px solid var(--card-border);
  border-radius: var(--shape-border-radius);
  text-decoration: none;
  overflow: hidden;
}
@media (min-width: 768px) {
  .category-card {
    grid-column: span 6;
  }
}
@media (min-width: 1024px) {
  .category-card {
    grid-column: span 4;
  }
}
@media (min-width: 1280px) {
  .category-card {
    grid-column: span 4;
  }
}
@media (min-width: 1600px) {
  .category-card {
    grid-column: span 5;
  }
}
.category-card__image {
  padding-top: 60%;
  background: var(--mono-0);
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom: 1px solid var(--card-border);
}
.category-card__image .image[data-image-fit=contain] {
  padding: 16px;
  box-sizing: border-box;
}
.category-card__copy {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 0;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
}
.category-card__copy__title {
  font-size: 1rem;
  padding: 0 0 0.4rem;
  font-weight: 400;
  word-break: break-word;
}

.filter-menu {
  grid-column: 1/span 4;
  grid-row: span 2;
}
@media (max-width: 1023px) {
  .filter-menu {
    display: none;
  }
}
@media (min-width: 1280px) {
  .filter-menu {
    grid-column: 1/span 4;
  }
}
@media (min-width: 1024px) {
  .filter-menu .section {
    margin: 0;
    min-width: unset !important;
  }
}
.filter-menu .section__header__quick-action {
  display: none;
}
.filter-menu__filter {
  border-bottom: 1px solid var(--section-border);
  background: var(--section-background);
}
.filter-menu__filter:not(:first-child) .filter-menu__filter__header__action {
  display: none;
}
.filter-menu__filter:first-child {
  position: sticky;
  top: 0;
  z-index: 10;
}
.filter-menu__filter:last-child {
  border-bottom: unset;
}
.filter-menu__filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 16px;
  min-height: 48px;
}
@media (min-width: 1024px) {
  .filter-menu__filter__header {
    min-height: 56px;
  }
}
.filter-menu__filter__header__title {
  padding: unset;
}
.filter-menu__filter__header__action {
  align-self: stretch;
  border-radius: 0;
}
@media (min-width: 1024px) {
  .filter-menu__filter__header__action {
    display: none;
  }
}
.filter-menu__filter__body {
  padding: 0 16px 24px;
  /* styling overrides for certain elements to preserve space and unify styling */
}
.filter-menu__filter__body .input-group, .filter-menu__filter__body .list {
  margin: 0;
}
.filter-menu__filter__body .list {
  gap: 0;
}
.filter-menu__filter__body .list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid var(--mono-4);
  width: 100%;
  height: 44px;
}
.filter-menu__filter__body .list__item:first-of-type {
  border-top: unset;
}
.filter-menu__filter__body .list__item a {
  width: 100%;
  margin: 0;
  text-decoration: unset;
}
.filter-menu__action {
  border-bottom: 1px solid var(--section-border);
  border-radius: unset;
}
.filter-menu__action:last-child {
  border-bottom: unset;
}

.filter-menu-button {
  display: none;
}
@media (max-width: 1023px) {
  .filter-menu-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column: 1/-1;
    grid-gap: 12px;
  }
  .filter-menu-button .button {
    flex: 1 1 auto;
  }
}

.paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-column: 1/-1;
  grid-gap: 12px;
  width: 100%;
  margin: 2rem 0 0;
  padding: 0;
  list-style-type: none;
}
.paginator__button {
  order: 2;
}
@media (min-width: 768px) {
  .paginator__button {
    order: initial;
  }
}
.paginator__button--step {
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .paginator__button--step {
    flex: initial;
  }
}
.paginator__pages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 100%;
  order: 1;
  gap: 8px;
}
@media (min-width: 768px) {
  .paginator__pages {
    order: initial;
    flex: initial;
  }
}

.search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 44px;
  margin-left: auto;
  padding: 0 !important;
  border-radius: var(--input-border-radius);
  border: 1px solid transparent;
  overflow: hidden;
  transition: width 0.48s cubic-bezier(0.61, -0.01, 0.33, 1.01), background 0.32s, color 0.32s, border-color 0.32s;
}
.search-bar:focus, .search-bar:focus-within {
  background: unset !important;
  border-color: var(--nav-accent);
  max-width: 220px;
}
.search-bar:focus .search-bar__icons .icon, .search-bar:focus-within .search-bar__icons .icon {
  color: var(--nav-accent) !important;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .search-bar:hover {
    background: unset !important;
    border-color: var(--nav-accent);
    max-width: 220px;
  }
  .search-bar:hover .search-bar__icons .icon {
    color: var(--nav-accent) !important;
  }
}
.search-bar:focus, .search-bar:focus-within {
  flex: 1;
  border-color: var(--nav-accent);
  width: 220px;
  background: unset;
}
.search-bar:focus input, .search-bar:focus-within input {
  cursor: text;
}
.search-bar:focus .icon, .search-bar:focus-within .icon {
  color: var(--nav-accent);
}
@media (min-width: 1024px) and (max-width: 1599px) {
  .search-bar {
    width: 48px;
  }
  .search-bar:focus, .search-bar:focus-within {
    background: rgba(var(--nav-accent-rgb), 0.56);
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1599px) and (hover: hover) and (pointer: fine) {
  .search-bar:hover {
    background: rgba(var(--nav-accent-rgb), 0.56);
  }
}
@media (min-width: 1600px) {
  .search-bar {
    width: 220px;
    border-color: var(--input-field-border);
  }
}
.search-bar__input {
  margin: 0;
}
.search-bar__input input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  border-right: unset;
  background: unset;
  border: unset;
  width: 44px;
  min-height: 44px;
  min-width: 44px;
  cursor: pointer;
  padding-left: 44px;
  padding-right: 0;
}
.search-bar__input input:focus {
  width: auto;
  padding-right: 12px;
}
@media (min-width: 768px) {
  .search-bar__input input {
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    padding-left: 48px;
  }
}
@media (min-width: 1600px) {
  .search-bar__input input {
    width: 100%;
  }
}
.search-bar__icons {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  color: var(--nav-color);
  transition: color 0.24s;
}
.search-bar__icons:focus, .search-bar__icons:focus-within {
  color: var(--nav-accent) !important;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .search-bar__icons:hover {
    color: var(--nav-accent) !important;
  }
}
@media (min-width: 768px) {
  .search-bar__icons {
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 1024px) and (max-width: 1599px) {
  .search-bar__icons {
    z-index: 0;
  }
}
.search-bar__icons .search-bar-loading-indicator {
  display: none;
}
.search-bar__icons .search-bar-loading-indicator[style*="display: block"] + .search-bar-search-button {
  display: none;
}

.search-results {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  visibility: visible;
  max-height: calc(100vh - 106px);
  opacity: 0;
  box-sizing: border-box;
}
.search-results .section {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  max-width: 1600px;
  flex: 1;
  margin: 0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
}
.search-results .section__content {
  max-height: 100%;
  overflow-y: scroll;
}
.search-results__categories {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 8px;
}
@media (min-width: 768px) {
  .search-results__categories {
    margin: 16px;
    gap: 16px;
  }
}
.search-results__products {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-gap: 8px;
  margin: 16px 8px;
}
@media (min-width: 768px) {
  .search-results__products {
    grid-column-gap: 12px;
    grid-template-columns: repeat(9, 1fr);
  }
}
@media (min-width: 1280px) {
  .search-results__products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1600px) {
  .search-results__products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 1920px) {
  .search-results__products {
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 768px) {
  .search-results__products {
    margin: 16px;
    grid-gap: 16px;
  }
}
.search-results__products .product-card {
  grid-column: span 3;
}
.search-results__no-results {
  margin: 16px 8px;
  text-align: center;
}
@media (min-width: 768px) {
  .search-results__no-results {
    margin: 24px 16px;
  }
}
.search-results__no-results p {
  margin: 0;
}

.overview .heading {
  margin-bottom: 24px;
}
.overview .heading__title {
  padding-top: unset;
}
@media (min-width: 768px) {
  .overview .heading {
    margin-bottom: 40px;
  }
}
.overview__paginator {
  grid-column: 1/-1;
}
@media (min-width: 1024px) {
  .overview__paginator--product {
    grid-column: span 8/-1;
  }
}
@media (min-width: 1600px) {
  .overview__paginator--product {
    grid-column: span 14/-1;
  }
}
.overview__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-column: 1/-1;
}
.overview__message__icon {
  font-size: 4rem;
  margin: 0 0 2rem;
}

.sub-categories {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  grid-column: 1/-1;
  grid-gap: 8px;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .sub-categories {
    grid-column: span 8/-1;
    grid-gap: 16px;
  }
}
@media (min-width: 1600px) {
  .sub-categories {
    grid-column: span 14/-1;
  }
}
.sub-categories__title {
  width: 100%;
}
@media (min-width: 1024px) {
  .sub-categories__title {
    padding-top: 0;
  }
}
.sub-categories__button {
  border-color: var(--card-border);
  border-width: 1px;
  gap: 8px;
}
@media (min-width: 768px) {
  .sub-categories__button {
    padding: 0 12px 0 8px;
  }
}
.sub-categories__button__image {
  width: 48px;
  height: 48px;
}

.products {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-column: 1/-1;
  grid-gap: 12px;
}
@media (min-width: 768px) {
  .products {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1600px) {
  .products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(20, 1fr);
  }
}
@media (min-width: 768px) {
  .products {
    grid-gap: 16px;
  }
}
@media (min-width: 1024px) {
  .products {
    grid-column: span 8/-1;
  }
}
@media (min-width: 1280px) {
  .products {
    grid-column: span 14/-1;
  }
}
@media (min-width: 1024px) {
  .products__product-amount {
    margin: 0;
  }
}
.products__title {
  padding-top: 0;
}

.categories {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-column: 1/-1;
  grid-gap: 12px;
}
@media (min-width: 768px) {
  .categories {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .categories {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1600px) {
  .categories {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1920px) {
  .categories {
    grid-template-columns: repeat(12, 20);
  }
}
@media (min-width: 768px) {
  .categories {
    grid-gap: var(--grid-gap-sm);
  }
}

.product {
  margin: 24px 0;
}
@media (max-width: 767px) {
  .product {
    gap: 16px;
  }
  .product > * {
    grid-column: 1/-1;
  }
}
@media (min-width: 768px) {
  .product {
    margin: 32px;
  }
}
@media (min-width: 1280px) {
  .product {
    margin: 40px 0;
  }
}
.product__basic-info {
  grid-row: 1;
}
@media (min-width: 768px) {
  .product__basic-info {
    grid-column: span 7/-1;
  }
}
@media (min-width: 1024px) {
  .product__basic-info {
    grid-column: span 6/-1;
  }
}
@media (min-width: 1600px) {
  .product__basic-info {
    grid-column: span 10/-1;
  }
}
.product__basic-info .heading__title {
  padding-top: 0;
}
.product__images {
  grid-column: 1/-1;
  grid-row: 2;
  width: 100%;
}
@media (min-width: 768px) {
  .product__images {
    grid-column: 1/span 5;
    grid-row: 1;
  }
}
@media (min-width: 1024px) {
  .product__images {
    grid-row: 1/span 2;
  }
}
@media (min-width: 1280px) {
  .product__images {
    grid-column: 1/span 5;
  }
}
@media (min-width: 1600px) {
  .product__images {
    grid-column: 1/span 7;
  }
}
.product__images .picture, .product__images .video__media {
  border-radius: unset;
}
.product__images__main-image {
  border: 1px solid var(--input-field-border);
  background: var(--mono-0);
}
.product__images__image-picker {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 4px;
  grid-gap: 8px;
}
@media (min-width: 768px) {
  .product__images__image-picker {
    grid-column-gap: 12px;
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1280px) {
  .product__images__image-picker {
    grid-column-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1600px) {
  .product__images__image-picker {
    grid-column-gap: 16px;
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (min-width: 1920px) {
  .product__images__image-picker {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (max-width: 767px) {
  .product__images__image-picker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* IE, Legacy Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Chrome, Safari and Opera */
    margin: 4px -16px;
    padding: 0 16px;
    width: calc(100% + 32px);
    overflow-x: scroll;
  }
  .product__images__image-picker::-webkit-scrollbar {
    display: none;
  }
  .product__images__image-picker__button {
    flex: 0 0 20%;
  }
}
.product__images__image-picker__button {
  padding: 0;
  background: white;
  border: 1px solid var(--input-field-border);
  cursor: pointer;
  transition: background 0.24s, color 0.24s, border-color 0.24s;
}
.product__images__image-picker__button[data-image-selected] {
  border-color: var(--brand-main);
}
.product__images__image-picker__button[data-image-selected] .image {
  opacity: 1;
}
.product__images__image-picker__button__image .image {
  opacity: 0.64;
  transition: opacity 0.24s;
}
.product__images__image-picker__button:focus, .product__images__image-picker__button:focus-within {
  border-color: var(--brand-main-active);
}
.product__images__image-picker__button:focus .image, .product__images__image-picker__button:focus-within .image {
  opacity: 1;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .product__images__image-picker__button:hover {
    border-color: var(--brand-main-active);
  }
  .product__images__image-picker__button:hover .image {
    opacity: 1;
  }
}
.product__discounts {
  grid-column: 1/-1;
  background: red;
}
@media (min-width: 1024px) {
  .product__discounts {
    grid-row: 2/span 1;
    grid-column: span 6/-1;
  }
}
.product__offers {
  grid-column: 1/-1;
}
@media (min-width: 1024px) {
  .product__offers {
    grid-column: span 6/-1;
  }
}
@media (min-width: 1600px) {
  .product__offers {
    grid-column: span 10/-1;
  }
}
.product__offers .input-group__container input.radio {
  display: none;
}
.product__offers .input-group__container .input-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  gap: 8px;
}
@media (min-width: 768px) {
  .product__offers .input-group__container .input-group {
    gap: 16px;
  }
}
@media (min-width: 1024px) {
  .product__offers .input-group__container .input-group {
    gap: 24px;
  }
}
.product__offers .input-group__container .input-group__input:checked + .input-group__label {
  border-color: var(--brand-main);
  background: rgba(var(--brand-main-rgb), 0.08);
}
.product__offers .input-group__container .input-group__input:checked + .input-group__label .image-fallback .icon {
  color: var(--brand-main);
}
.product__offers .input-group__container .input-group__label {
  display: grid;
  grid-template-columns: 64px auto;
  gap: 8px;
  padding: 0 8px 0 0;
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  background: var(--mono-0);
  overflow: hidden;
  transition: background 0.24s, color 0.24s, border-color 0.24s;
}
@media (min-width: 768px) {
  .product__offers .input-group__container .input-group__label {
    grid-template-columns: 80px auto 1fr;
    padding: 0 12px 0 0;
    gap: 16px;
  }
}
@media (min-width: 1024px) {
  .product__offers .input-group__container .input-group__label {
    grid-template-columns: 80px auto;
  }
}
@media (min-width: 1280px) {
  .product__offers .input-group__container .input-group__label {
    grid-template-columns: 96px auto 1fr;
  }
}
.product__offers .input-group__container .input-group__label:focus, .product__offers .input-group__container .input-group__label:focus-within {
  border-color: var(--brand-main);
  background: rgba(var(--brand-main-rgb), 0.16) !important;
}
.product__offers .input-group__container .input-group__label:focus .image-fallback .icon, .product__offers .input-group__container .input-group__label:focus-within .image-fallback .icon {
  color: var(--brand-main);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .product__offers .input-group__container .input-group__label:hover {
    border-color: var(--brand-main);
    background: rgba(var(--brand-main-rgb), 0.16) !important;
  }
  .product__offers .input-group__container .input-group__label:hover .image-fallback .icon {
    color: var(--brand-main);
  }
}
.product__offers .input-group__container .input-group__label:active {
  background: rgba(var(--brand-main-rgb), 0.32) !important;
}
.product__offers .input-group__container .input-group__label:active .image-fallback .icon {
  opacity: 0.64;
}
.product__offers .input-group__container .input-group__label__image {
  grid-row: 1/span 2;
  min-height: 64px;
  align-self: stretch;
  background: var(--mono-0);
  border-radius: unset;
}
@media (min-width: 768px) {
  .product__offers .input-group__container .input-group__label__image {
    grid-row: 1;
    min-height: 80px;
  }
}
@media (min-width: 1024px) {
  .product__offers .input-group__container .input-group__label__image {
    grid-row: 1/span 2;
  }
}
@media (min-width: 1280px) {
  .product__offers .input-group__container .input-group__label__image {
    flex: 0 0 96px;
    min-height: 96px;
  }
}
.product__offers .input-group__container .input-group__label__image .image-fallback {
  padding: 8px;
  box-sizing: border-box;
}
.product__offers .input-group__container .input-group__label__image .image-fallback__icon {
  font-size: 2em;
}
.product__offers .input-group__container .input-group__label__image--hidden {
  visibility: hidden;
}
.product__offers .input-group__container .input-group__label__copy {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 8px 0 0;
}
.product__offers .input-group__container .input-group__label__copy:last-child {
  margin-bottom: 8px;
}
.product__offers .input-group__container .input-group__label__copy__name {
  display: inline-block;
  font-weight: 600;
  overflow-wrap: anywhere;
}
.product__offers .input-group__container .input-group__label__copy__description {
  display: inline-block;
  margin: 4px 0 0;
  overflow-wrap: anywhere;
}
.product__offers .input-group__container .input-group__label__pricing {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  grid-column: 2/-1;
  margin-bottom: 8px;
}
.product__offers .input-group__container .input-group__label__pricing__price {
  font-weight: 600;
}
@media (min-width: 768px) {
  .product__offers .input-group__container .input-group__label__pricing__price {
    margin: 8px 0;
  }
}
.product__offers .input-group__container .input-group__label__pricing__price--discounted {
  font-weight: 400;
  text-decoration: line-through;
}
.product__offers .input-group__container .input-group__label__pricing__discount-price {
  margin: 0;
  white-space: nowrap;
}
.product__offers .banner {
  margin-top: 40px;
}
.product__offers__add-to-cart {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  gap: 8px;
  margin: 40px 0 24px auto;
  padding: 0;
}
.product__offers__add-to-cart:last-child {
  margin-bottom: unset;
}
@media (min-width: 768px) {
  .product__offers__add-to-cart {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }
}
.product__offers__add-to-cart .input-group {
  flex: 1 1 auto;
  margin: 0;
  gap: 0;
}
@media (min-width: 768px) {
  .product__offers__add-to-cart .input-group {
    max-width: 160px;
  }
}
.product__offers__add-to-cart .input-group__field {
  flex: 1;
}
.product__offers__add-to-cart .input-group__field input {
  width: 100%;
  margin: 0;
  border-left: unset;
  border-right: unset;
  border-color: var(--button-main);
  transition: background 0.24s, color 0.24s, border-color 0.24s;
}
.product__offers__add-to-cart .input-group__field input:focus, .product__offers__add-to-cart .input-group__field input:focus-within {
  border-color: var(--button-main-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .product__offers__add-to-cart .input-group__field input:hover {
    border-color: var(--button-main-active);
  }
}
@media (max-width: 767px) {
  .product__offers__add-to-cart .input-group .button {
    flex: 0 0 96px;
  }
}
.product__offers__add-to-cart > .button {
  flex: 1 0 auto;
}
@media (min-width: 768px) {
  .product__offers__add-to-cart > .button {
    flex: 0 0 auto;
  }
}
.product__details {
  grid-column: 1/-1;
  grid-row: 4;
}
@media (min-width: 768px) {
  .product__details {
    grid-row: 3;
  }
}
@media (min-width: 1280px) {
  .product__details {
    grid-column: 1/span 8;
  }
}
@media (min-width: 1600px) {
  .product__details {
    grid-column: 1/span 10;
  }
}
.product__downloads {
  grid-row: 5;
}
@media (min-width: 768px) {
  .product__downloads {
    grid-row: 4;
  }
}
@media (min-width: 1024px) {
  .product__downloads {
    grid-column: 1/span 5;
    grid-row: 3;
  }
}
@media (min-width: 1280px) {
  .product__downloads {
    grid-column: 1/span 5;
  }
}
.product__downloads__button {
  margin: 8px 0 0;
}

.related-products {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-column: 1/-1;
  grid-gap: var(--grid-gap-xs);
}
@media (min-width: 768px) {
  .related-products {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .related-products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1600px) {
  .related-products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(16, 1fr);
  }
}
@media (min-width: 768px) {
  .related-products {
    grid-gap: var(--grid-gap-sm);
  }
}
.related-products .heading__title {
  padding-top: unset;
}
@media (min-width: 768px) {
  .related-products .product-card {
    grid-column: span 6;
  }
}
@media (min-width: 1024px) {
  .related-products .product-card {
    grid-column: span 4;
  }
}
@media (min-width: 1280px) {
  .related-products .product-card {
    grid-column: span 3;
  }
}
@media (min-width: 1600px) {
  .related-products .product-card {
    grid-column: span 4;
  }
}

.checkout-header {
  background: unset;
  padding-bottom: 16px;
  max-width: 960px;
}
@media (min-width: 768px) {
  .checkout-header {
    padding-top: 64px;
  }
}
.checkout-header .heading {
  margin-top: 24px;
}
.checkout-header .heading__title {
  padding-top: unset;
}

.progress-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column: 1/-1;
  gap: 16px;
  margin-top: 24px;
}
.progress-bar__stage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.progress-bar__stage--active .progress-bar__stage__button__label {
  display: initial;
}
.progress-bar__stage__button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}
@media (min-width: 768px) {
  .progress-bar__stage__button {
    gap: 12px;
  }
}
.progress-bar__stage__button__icon {
  flex: 0 0 32px;
}
.progress-bar__stage__button__label {
  display: none;
  color: var(--text-color-dark);
}
@media (min-width: 768px) {
  .progress-bar__stage__button__label {
    display: initial;
  }
}
.progress-bar__stage__spacer {
  display: block;
  height: 1px;
  width: 16px;
  background: var(--mono-4);
}
.progress-bar__stage:last-child .progress-bar__stage__spacer {
  display: none;
}

.checkout-form {
  max-width: 960px;
}
@media (min-width: 768px) {
  .checkout-form {
    margin: 0;
  }
}
.checkout-form__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column: 1/-1;
  flex: 1 1 auto;
  margin: 16px 0 64px;
  width: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  .checkout-form__action {
    margin: 24px 0 48px;
  }
}
.checkout-form__payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  margin: 16px 0 48px;
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .checkout-form__payment {
    flex-direction: row;
    margin: 24px 0 48px;
    gap: 12px;
  }
}
.checkout-form__payment__note {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 100%;
  gap: 12px;
}
.checkout-form__payment__note__icon {
  margin-top: 6px;
  color: var(--brand-main);
  font-size: 1.6rem;
}
.checkout-form__payment__note__text {
  margin: 0;
  color: var(--mono-6);
  font-size: 0.8rem;
}
.checkout-form__payment .button {
  width: 100%;
}
@media (min-width: 768px) {
  .checkout-form__payment .button {
    width: auto;
  }
}

.selected-method {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 16px 12px;
}
.selected-method__image {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
}
@media (min-width: 768px) {
  .selected-method__image {
    flex: 0 0 64px;
    width: 64px;
    height: 64px;
  }
}
.selected-method__label {
  margin: 0 0 0 8px;
}
.selected-method__image + .selected-method__label {
  margin: 0;
}

/* Custom radio input styling */
.checkout-method-picker {
  margin: 12px 0 0;
}
.checkout-method-picker .input-group__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin: 0;
}
@media (min-width: 768px) {
  .checkout-method-picker .input-group__container {
    gap: 16px;
    flex-direction: row;
  }
}

.input-group--checkout-method {
  margin: 0;
  flex: 0 0 auto;
}
.input-group--checkout-method .radio {
  display: none;
}
.input-group--checkout-method .radio:checked + label {
  border-color: var(--brand-main);
  background: rgba(var(--brand-main-rgb), 0.08);
}
.input-group--checkout-method .label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 4px 8px 8px;
  border: 1px solid var(--input-field-border);
  border-radius: var(--input-border-radius);
  background: var(--input-field-background);
  min-width: 116px;
  height: 100%;
  transition: 0.24s cubic-bezier(0.22, 1, 0.36, 1);
}
@media (min-width: 768px) {
  .input-group--checkout-method .label {
    gap: 12px;
    padding: 8px 8px 12px;
  }
}
.input-group--checkout-method .label:focus, .input-group--checkout-method .label:focus-within {
  border-color: var(--brand-main-active);
  background: rgba(var(--brand-main-rgb), 0.16);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .input-group--checkout-method .label:hover {
    border-color: var(--brand-main-active);
    background: rgba(var(--brand-main-rgb), 0.16);
  }
}
.input-group--checkout-method .label--disabled {
  border-color: var(--mono-5);
  background: var(--mono-2);
  cursor: default;
}
.input-group--checkout-method .label--disabled:focus, .input-group--checkout-method .label--disabled:focus-within {
  border-color: var(--mono-5);
  background: var(--mono-2);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .input-group--checkout-method .label--disabled:hover {
    border-color: var(--mono-5);
    background: var(--mono-2);
  }
}
.input-group--checkout-method .label__image {
  width: 48px;
  height: 48px;
}
@media (min-width: 768px) {
  .input-group--checkout-method .label__image {
    width: 64px;
    height: 64px;
  }
}

.pricing-overview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 18px 12px;
  margin: 0;
  list-style-type: none;
}
@media (min-width: 768px) {
  .pricing-overview {
    padding: 20px;
  }
}
.pricing-overview__label {
  font-weight: 600;
}
.pricing-overview__price {
  justify-self: end;
}
.pricing-overview__price--lg * {
  color: var(--brand-main);
}
.pricing-overview__empty-row {
  grid-column: 1/-1;
  height: 12px;
}

.checkout-login-banner {
  grid-column: 1/-1;
}
.checkout-login-banner__container {
  border: 1px solid var(--state-info);
  background: rgba(var(--state-info-rgb), 0.012);
  margin-top: 16px;
  padding: 12px;
  border-radius: var(--shape-border-radius);
}
.checkout-login-banner__container #login-form {
  grid-auto-flow: dense;
  margin: 0;
  padding: 0;
}
.checkout-login-banner__container .heading {
  margin-top: 0;
}
.checkout-login-banner__container .heading__title {
  font-size: 1.12em;
  color: var(--state-info-label);
  padding-top: 0;
}
.checkout-login-banner__container .heading__description {
  margin-top: 0;
}
.checkout-login-banner__container .link {
  grid-column: 1/-1;
  font-size: 0.88em;
}
.checkout-login-banner__container .link__label {
  font-size: inherit;
}
.checkout-login-banner__container .button {
  grid-column: 1/-1;
  margin: 16px 0 0 auto;
}
@media (min-width: 768px) {
  .checkout-login-banner__container .button {
    grid-column: span 4/-1;
    align-self: end;
  }
}

.order-list {
  margin: 0;
  padding: 0;
}
.order-list__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  background: var(--list-row-odd);
  list-style-type: none;
  padding: 8px 8px;
  border-left: 4px solid var(--state-info);
}
@media (min-width: 768px) {
  .order-list__item {
    flex-direction: row;
    padding: 12px 24px;
    border-width: 6px;
  }
}
.order-list__item--waiting-for-payment {
  border-color: var(--state-warning);
}
.order-list__item--done {
  border-color: var(--state-success);
}
.order-list__item--failed {
  border-color: var(--state-error);
}
.order-list__item:nth-child(even) {
  background: var(--list-row-even);
}
.order-list__item__info {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .order-list__item__info {
    padding: unset;
  }
}
.order-list__item__info * {
  margin: 0;
  padding: 0;
}
.order-list__item__info__title {
  grid-column: 1;
  grid-row: 1;
}
.order-list__item__info__date {
  grid-column: 2;
  grid-row: 1;
  text-align: right;
}
.order-list__item__info__price {
  grid-column: 2;
  grid-row: 2;
  text-align: right;
}
@media (min-width: 768px) {
  .order-list__item__info__price {
    text-align: left;
  }
}
.order-list__item__info__status {
  grid-column: 1;
  grid-row: 2;
}
.order-list__item__actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid var(--mono-3);
  width: 100%;
}
@media (min-width: 768px) {
  .order-list__item__actions {
    border-top: unset;
    justify-content: flex-end;
  }
}

.product-list__products {
  grid-column: 1/-1;
  /*
   * Flexbox for touch input devices
   */
  /*
   * Grid for mouse input devices
   */
}
@media only screen and (hover: none) and (pointer: coarse) {
  .product-list__products {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* IE, Legacy Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Chrome, Safari and Opera */
    gap: 16px;
    margin: 24px -16px 0;
    padding-right: 80px;
    overflow: scroll hidden;
    scroll-snap-type: x mandatory;
  }
  .product-list__products::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (min-width: 768px) {
  .product-list__products {
    margin: 24px -24px 0;
    gap: 24px;
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
  .product-list__products {
    margin: 24px -40px 0;
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (min-width: 1280px) {
  .product-list__products {
    display: grid;
    padding-right: 0;
  }
}
@media only screen and (hover: hover) and (pointer: fine) {
  .product-list__products {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 8px;
    box-sizing: border-box;
    justify-content: center;
    gap: 16px;
    margin: 24px 0 0;
  }
}
@media only screen and (hover: hover) and (pointer: fine) and (min-width: 768px) {
  .product-list__products {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media only screen and (hover: hover) and (pointer: fine) and (min-width: 1280px) {
  .product-list__products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(16, 1fr);
  }
}
@media only screen and (hover: hover) and (pointer: fine) and (min-width: 1600px) {
  .product-list__products {
    grid-column-gap: 16px;
    grid-template-columns: repeat(16, 1fr);
  }
}
@media only screen and (hover: hover) and (pointer: fine) and (min-width: 1920px) {
  .product-list__products {
    grid-template-columns: repeat(16, 1fr);
  }
}
.product-list__products .product-card {
  grid-column: span 3;
  flex: 0 0 240px;
  scroll-snap-align: center;
  align-self: stretch;
}
@media (min-width: 768px) {
  .product-list__products .product-card {
    grid-column: span 6;
    flex: 0 0 280px;
    scroll-snap-align: unset;
  }
}
@media (min-width: 1024px) {
  .product-list__products .product-card {
    grid-column: span 3;
  }
}
@media (min-width: 1280px) {
  .product-list__products .product-card {
    grid-column: span 3;
  }
}
@media (min-width: 1600px) {
  .product-list__products .product-card {
    grid-column: span 4;
  }
}
.product-list__products .product-card__copy {
  flex: 1;
}
@media only screen and (hover: none) and (pointer: coarse) {
  .product-list__products .product-card:first-of-type {
    margin-left: 16px;
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (min-width: 768px) {
  .product-list__products .product-card:first-of-type {
    margin-left: 24px;
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
  .product-list__products .product-card:first-of-type {
    margin-left: 40px;
  }
}
@media only screen and (hover: none) and (pointer: coarse) and (min-width: 1280px) {
  .product-list__products .product-card:first-of-type {
    margin-left: 0;
  }
}