@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.search-bar {
  @include m.flex(end, center);
  position: relative;
  width: 44px;
  margin-left: auto;
  padding: 0 !important;
  border-radius: var(--input-border-radius);
  border: 1px solid transparent;
  overflow: hidden;
  transition: width 0.48s cubic-bezier(0.61, -0.01, 0.33, 1.01), background 0.32s, color 0.32s, border-color 0.32s;

  @include m.interaction() {
    background: unset !important;
    border-color: var(--nav-accent);
    max-width: 220px;

    .search-bar__icons .icon {
      color: var(--nav-accent) !important;
    }
  }

  @include m.interaction(focus) {
    flex: 1;
    border-color: var(--nav-accent);
    width: 220px;
    background: unset;

    input {
      cursor: text;
    }

    .icon {
      color: var(--nav-accent);
    }
  }

  @include m.bp(md, xl) {
    width: 48px;

    @include m.interaction(hover) {
      background: rgba(var(--nav-accent-rgb), 0.56);
    }
  }

  @include m.bp(xl) {
    width: 220px;
    border-color: var(--input-field-border);
  }

  &__input {
    margin: 0;

    input {
      @include m.position(absolute, 0, 0, 0, 0);
      margin: 0;
      border-right: unset;
      background: unset;
      border: unset;
      width: 44px;
      min-height: 44px;
      min-width: 44px;
      cursor: pointer;
      padding-left: 44px;
      padding-right: 0;

      &:focus {
        width: auto;
        padding-right: 12px;
      }

      @include m.bp(sm) {
        width: 48px;
        min-height: 48px;
        min-width: 48px;
        padding-left: 48px;
      }

      @include m.bp(xl) {
        width: 100%;
      }
    }
  }

  &__icons {
    @include m.position(absolute, 0, null, 0, 0);
    @include m.flex(center, center);
    width: 44px;
    height: 44px;
    color: var(--nav-color);
    transition: color 0.24s;

    @include m.interaction() {
      color: var(--nav-accent) !important;
    }

    @include m.bp(sm) {
      width: 48px;
      height: 48px;
    }

    @include m.bp(md, xl) {
      z-index: 0;
    }

    .search-bar-loading-indicator {
      display: none;

      &[style*="display: block"] + .search-bar-search-button {
        display: none;
      }
    }
  }
}

.search-results {
  @include m.position(absolute, 0, 0, 0, 0);
  @include m.flex(center, null);
  visibility: visible;
  max-height: calc(100vh - 106px); // viewport - (nav height + spacing)
  opacity: 0;
  box-sizing: border-box;

  .section {
    @include m.flex(start, stretch, column);
    max-width: v.$search-result-max-width;
    flex: 1;
    margin: 0;
    box-shadow: v.$shadow-overlay;

    &__content {
      max-height: 100%;
      overflow-y: scroll;
    }
  }

  &__categories {
    @include m.flex(start, center);
    flex-wrap: wrap;
    gap: 8px;
    margin: 16px 8px;

    @include m.bp(sm) {
      margin: 16px;
      gap: 16px;
    }
  }

  &__products {
    @include m.grid(6, 9, 12, 18, 1fr);
    grid-gap: 8px;
    margin: 16px 8px;

    @include m.bp(sm) {
      margin: 16px;
      grid-gap: 16px;
    }

    .product-card {
      grid-column: span 3;
    }
  }

  &__no-results {
    margin: 16px 8px;
    text-align: center;

    @include m.bp(sm) {
      margin: 24px 16px;
    }

    p {
      margin: 0;
    }
  }
}