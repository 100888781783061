@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.product-list {

  &__products {
    grid-column: 1 / -1;

    /*
     * Flexbox for touch input devices
     */
    @include m.inputMethod(touch) {
      @include m.flex(start, start);
      @include m.hide-scrollbars;
      gap: 16px;
      margin: 24px -#{map-get(v.$section-padding-sizes, 'xs')} 0;
      padding-right: 80px;
      overflow: scroll hidden;
      scroll-snap-type: x mandatory;

      @include m.bp(sm) {
        margin: 24px -#{map-get(v.$section-padding-sizes, 'sm')} 0;
        gap: 24px;
      }

      @include m.bp(md) {
        margin: 24px -#{map-get(v.$section-padding-sizes, 'md')} 0;
      }

      @include m.bp(lg) {
        display: grid;
        padding-right: 0;
      }
    }

    /*
     * Grid for mouse input devices
     */
    @include m.inputMethod(mouse) {
      @include m.grid(6, 12, 16, 16, 1fr);
      gap: 16px;
      margin: 24px 0 0;
    }

    .product-card {
      grid-column: span 3;
      flex: 0 0 240px;
      scroll-snap-align: center;
      align-self: stretch;

      @include m.bp(sm) {
        grid-column: span 6;
        flex: 0 0 280px;
        scroll-snap-align: unset;
      }

      @include m.bp(md) {
        grid-column: span 3;
      }

      @include m.bp(lg) {
        grid-column: span 3;
      }

      @include m.bp(xl) {
        grid-column: span 4;
      }

      &__copy {
        flex: 1;
      }

      @include m.inputMethod(touch) {
        &:first-of-type {
          margin-left: map-get(v.$section-padding-sizes, 'xs');

          @include m.bp(sm) {
            margin-left: map-get(v.$section-padding-sizes, 'sm');
          }

          @include m.bp(md) {
            margin-left: map-get(v.$section-padding-sizes, 'md');
          }

          @include m.bp(lg) {
            margin-left: 0;
          }
        }
      }
    }
  }
}