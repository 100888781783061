@use '../../../../../resources/scss/mixins' as m;

.paginator {
  @include m.flex(between, center);
  flex-wrap: wrap;
  grid-column: 1 / -1;
  grid-gap: 12px;
  width: 100%;
  margin: 2rem 0 0;
  padding: 0;
  list-style-type: none;

  &__button {
    order: 2;

    @include m.bp(sm) {
      order: initial;
    }

    &--step {
      flex: 1 1 auto;

      @include m.bp(sm) {
        flex: initial;
      }
    }
  }

  &__pages {
    @include m.flex(between, center);
    flex: 1 0 100%;
    order: 1;
    gap: 8px;

    @include m.bp(sm) {
      order: initial;
      flex: initial;
    }
  }
}