@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.breadcrumbs {
  @include m.flex(start, center);
  @include m.hide-scrollbars;
  grid-column: 1 / -1;
  width: 100%;
  margin: 24px 0 0;
  padding: 0 map-get(v.$section-padding-sizes, xs);
  gap: 4px;
  overflow-x: scroll;
  box-sizing: border-box;

  &__separator {
    flex: 0;
    color: var(--mono-4);
    font-size: 1.4rem;
  }

  .tag {
    flex: 0 0 auto;
    color: var(--mono-6);

    @include m.interaction() {
      color: var(--brand-main-active);
    }
  }
}

body[data-action-nav] .breadcrumbs {
  @include m.bp(null, sm) {
    margin: 80px 0 0;
  }
}