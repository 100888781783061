@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.cart {
  @include m.flex(start, between);
  flex-direction: column;
  gap: 16px;
  margin: 16px 8px;
  width: calc(100% - 16px);

  @include m.bp(sm) {
    gap: 16px;
    width: calc(100% - 32px);
    margin: 16px;
  }

  &__products {
    border: 1px solid var(--card-border);
    overflow: hidden;
    border-radius: var(--shape-border-radius);
  }

  &__note {
    margin: 0;
    text-align: center;
    color: var(--mono-6);
    font-size: 0.88em;
  }
}

.cart-product-card {
  @include m.flex(between, center);
  gap: 8px;
  flex-wrap: wrap;
  padding: 8px;
  background: var(--mono-1);

  @include m.bp(sm) {
    gap: 16px;
    flex-wrap: nowrap;
  }

  &:nth-child(odd) {
    background: var(--mono-2);
  }

  p {
    margin: 0;
  }

  &__image {
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    background: var(--mono-0);
    border: 1px solid var(--card-border);

    @include m.bp(sm) {
      width: 64px;
      height: 64px;
      flex: 0 0 64px;
    }

    .image-fallback .icon {
      font-size: 2rem;
    }
  }

  &__copy {
    flex: 1 1 calc(100% - 64px);
    align-self: start;

    &__title {
      font-weight: v.$font-semi-bold;
    }

    &__description {
      font-size: 0.88rem;
      color: var(--mono-6);
    }
  }

  &__pricing {
    @include m.flex(end, center);
    gap: 8px;
    flex: 1 1 100%;
    align-self: center;

    @include m.bp(sm) {
      flex: 0 0 auto;
    }

    &__calculation {
      color: var(--mono-6);
    }

    &__result {
      font-weight: v.$font-semi-bold;
    }
  }
}