@use '../../../../../resources/scss/variables' as v;
@use '../../../../../resources/scss/mixins' as m;

.filter-menu {
  grid-column: 1 / span 4;
  grid-row: span 2;

  @include m.bp(null, md) {
    display: none;
  }

  @include m.bp(lg) {
    grid-column: 1 / span 4;
  }

  .section {

    @include m.bp(md) {
      margin: 0;
      min-width: unset !important;
    }

    &__header__quick-action {
      display: none;
    }
  }

  &__filter {
    border-bottom: 1px solid var(--section-border);
    background: var(--section-background);

    &:not(:first-child) &__header__action {
      display: none;
    }

    &:first-child {
      @include m.position(sticky, 0);
      z-index: 10;
    }

    &:last-child {
      border-bottom: unset;
    }

    &__header {
      @include m.flex(between, center);
      padding: 0 0 0 16px;
      min-height: 48px;

      @include m.bp(md) {
        min-height: 56px;
      }

      &__title {
        padding: unset;
      }

      &__action {
        align-self: stretch;
        border-radius: 0;

        @include m.bp(md) {
          display: none;
        }
      }
    }

    &__body {
      padding: 0 16px 24px;

      /* styling overrides for certain elements to preserve space and unify styling */
      .input-group, .list {
        margin: 0;
      }

      .list {
        gap: 0;

        &__item {
          @include m.flex(start, center);
          border-top: 1px solid var(--mono-4);
          width: 100%;
          height: v.$button-size;

          &:first-of-type {
            border-top: unset;
          }

          a {
            width: 100%;
            margin: 0;
            text-decoration: unset;
          }
        }
      }
    }
  }

  &__action {
    border-bottom: 1px solid var(--section-border);
    border-radius: unset;

    &:last-child {
      border-bottom: unset;
    }
  }
}

.filter-menu-button {
  display: none;

  @include m.bp(null, md) {
    @include m.flex(between, center);
    grid-column: 1 / -1;
    grid-gap: 12px;

    .button {
      flex: 1 1 auto;
    }
  }
}