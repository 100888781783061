@use '../../../../../resources/scss/mixins' as m;

.order-list {
  margin: 0;
  padding: 0;

  &__item {
    @include m.flex(between, start, column);
    gap: 4px;
    background: var(--list-row-odd);
    list-style-type: none;
    padding: 8px 8px;
    border-left: 4px solid var(--state-info);

    @include m.bp(sm) {
      flex-direction: row;
      padding: 12px 24px;
      border-width: 6px;
    }

    &--waiting-for-payment {
      border-color: var(--state-warning);
    }

    &--done {
      border-color: var(--state-success);
    }

    &--failed {
      border-color: var(--state-error);
    }

    &:nth-child(even) {
      background: var(--list-row-even);
    }

    &__info {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 4px;
      width: 100%;
      box-sizing: border-box;

      @include m.bp(sm) {
        padding: unset;
      }

      * {
        margin: 0;
        padding: 0;
      }

      &__title {
        grid-column: 1;
        grid-row: 1;
      }

      &__date {
        grid-column: 2;
        grid-row: 1;
        text-align: right;
      }

      &__price {
        grid-column: 2;
        grid-row: 2;
        text-align: right;

        @include m.bp(sm) {
          text-align: left;
        }
      }

      &__status {
        grid-column: 1;
        grid-row: 2;
      }
    }

    &__actions {
      @include m.flex(start, center);
      border-top: 1px solid var(--mono-3);
      width: 100%;

      @include m.bp(sm) {
        border-top: unset;
        justify-content: flex-end;
      }
    }
  }
}